import { useState } from 'react';

import { getAppEvents } from '@grafana/runtime';
import { Button, IconButton, Modal } from '@grafana/ui';
import { notifyApp } from 'app/core/actions';
import { createSuccessNotification } from 'app/core/copy/appNotification';
import { t, Trans } from 'app/core/internationalization';
import { dispatch } from 'app/store/store';
import { ShowConfirmModalEvent } from 'app/types/events';

import { type OnSelectQueryType } from '../../../features/explore/QueryLibrary/types';
import { queryLibaryTrackDeleteQuery, queryLibraryTrackAddOrEditDescription } from '../QueryLibraryAnalyticsEvents';
import { QueryTemplateForm } from '../QueryTemplateForm';
import { useDeleteQueryTemplateMutation } from '../api';

import { useQueryLibraryListStyles } from './styles';
import { QueryTemplateRow } from './types';

interface ActionsCellProps {
  queryTemplate: QueryTemplateRow;
  onSelectQuery: OnSelectQueryType;
}

function ActionsCell({ queryTemplate, onSelectQuery }: ActionsCellProps) {
  const [deleteQueryTemplate] = useDeleteQueryTemplateMutation();
  const [editFormOpen, setEditFormOpen] = useState(false);
  const styles = useQueryLibraryListStyles();

  const onDeleteQuery = (queryUid: string) => {
    const performDelete = (queryUid: string) => {
      deleteQueryTemplate({
        name: queryUid,
        deleteOptions: {},
      });
      dispatch(notifyApp(createSuccessNotification(t('explore.query-library.query-deleted', 'Query deleted'))));
      queryLibaryTrackDeleteQuery();
    };

    getAppEvents().publish(
      new ShowConfirmModalEvent({
        title: t('explore.query-library.delete-query-title', 'Delete query'),
        text: t(
          'explore.query-library.delete-query-text',
          "You're about to remove this query from the query library. This action cannot be undone. Do you want to continue?"
        ),
        yesText: t('query-library.delete-query-button', 'Delete query'),
        icon: 'trash-alt',
        onConfirm: () => performDelete(queryUid),
      })
    );
  };

  return (
    <div className={styles.cell}>
      <IconButton
        className={styles.actionButton}
        size="lg"
        name="trash-alt"
        title={t('explore.query-library.delete-query', 'Delete query')}
        tooltip={t('explore.query-library.delete-query', 'Delete query')}
        onClick={() => {
          if (queryTemplate.uid) {
            onDeleteQuery(queryTemplate.uid);
          }
        }}
      />
      <IconButton
        className={styles.actionButton}
        size="lg"
        name="comment-alt"
        title={t('explore.query-library.add-edit-description', 'Add/edit description')}
        tooltip={t('explore.query-library.add-edit-description', 'Add/edit description')}
        onClick={() => {
          setEditFormOpen(true);
          queryLibraryTrackAddOrEditDescription();
        }}
      />
      <Button variant="primary" onClick={() => onSelectQuery(queryTemplate.query)}>
        <Trans i18nKey="explore.query-library.select-query">
          Select query
        </Trans>
      </Button>
      <Modal
        title={t('explore.query-template-modal.edit-title', 'Edit query')}
        isOpen={editFormOpen}
        onDismiss={() => setEditFormOpen(false)}
      >
        <QueryTemplateForm
          onCancel={() => setEditFormOpen(false)}
          templateData={queryTemplate}
          onSave={() => {
            setEditFormOpen(false);
          }}
        />
      </Modal>
    </div>
  );
}

export default ActionsCell;
